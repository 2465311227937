import axios from 'axios';
import config from "./config";

class requests {

    static get = (path, params= {}, headers={}) => {
        const qsList = [];
        for (const [key, value] of Object.entries(params)) {
            qsList.push(`${key}=${value}`);
        }
        const qs = qsList.join('&');
        const token = localStorage.getItem('token')
        return axios({
            method: 'get',
            url: `${config.host}${path}?${qs}`,
            headers: {
                'Boagent': token
            }
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                data: error.response.data,
                status: error.response.status
            };
        });
    }

    static post = (path, params = {}) => {
        const token = localStorage.getItem('token')
        return axios({
            method: 'post',
            url: `${config.host}${path}`,
            data: params,
            headers: {
                'Boagent': token
            }
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                status: error.response.status,
                data: error.response.data
            };
        });
    }

}

export default requests;