import {message} from 'antd';


export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function beforeUpload(file) {
  const allowed = file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'application/pdf';
  if (!allowed) {
    message.error('You can only upload JPG/PNG/PDF file!');
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error('Image must be smaller than 10MB!');
  }
  return allowed && isLt10M;
}