import React, {Component} from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const Wrapper = styled.div`
  padding: 25px;
  text-align: center;
`

class Spinner extends Component {

  render(){
    return <Wrapper><Spin indicator={<LoadingOutlined />} /></Wrapper>;
  }

}

export default Spinner;

