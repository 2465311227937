import React, {Component} from 'react';
import styled from 'styled-components';
import background from './assets/img/background.webp';
import Login from "./components/Login";
import './App.css';
import 'antd/dist/antd.css';
import 'react-chat-elements/dist/main.css';
import Main from "./components/Main";
import MainMobile from "./components/MainMobile";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Stats from "./components/Stats";


const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("${background}");
  background-size: cover;
  background-position: center;
  padding: 20px;
  
   @media screen and (max-width: 767px) {
    padding: 0;
  }
`;


export default class App extends Component {

  constructor(props, context) {
    super(props, context);
    const token = localStorage.getItem('token');
    this.state = {
      token: token
    }
  }

  authorize = token => {
    localStorage.setItem('token', token);
    this.setState({token: token});
  }

  render() {
    const {token} = this.state;
    return (
        <BrowserRouter>
          <Routes>
            <Route path="/stats" element={<Wrapper><Stats/></Wrapper>}/>
            <Route path="/" element={<Wrapper>
                {token ? (
                    window.innerWidth > 737 ? <Main/> : <MainMobile/>
                ) : (
                    <Login authorize={this.authorize}/>
                )}
              </Wrapper>} />
          </Routes>
        </BrowserRouter>

    )
  }

}