import React, {Component, createRef} from "react";
import styled from "styled-components";
import {MessageList, Input, SystemMessage} from "react-chat-elements";
import {Button, Upload, Typography, message} from "antd";
import requests from "../utils/requests";
import message_sent from '../assets/sounds/message_sent.mp3';
import ImageViewer from 'react-simple-image-viewer';
import {PaperClipOutlined, LoadingOutlined, DeleteOutlined} from '@ant-design/icons';
import {getBase64, beforeUpload} from '../utils/funcs';


const Wrapper = styled.div`
    width: 100%;
    height: calc(100% - 155px);
    overflow-y: scroll;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 85% 5% 10%;
    position: absolute;
    width: calc(100% - 30px);
    bottom: 20px;
    grid-gap: 5px;
    
    @media screen and (max-width: 767px) {
        grid-template-columns: 70% 10% 20%;
    }
`;
const Attachment = styled.div`
    width: 100%;
    position: absolute;
    bottom: -23px;
`;
const {Paragraph} = Typography;


export default class Chat extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            input: null,
            loading: false,
            wasKeyDown: false,
            viewerIsOpen: false,
            imageIndex: null,
            fileName: null,
            fileUrl: null,
            fileLoading: false
        }
    }

    inputRef = createRef();
    messagesEnd = createRef();

    onKeyDown = async e => {
        const {wasKeyDown} = this.state;
        if (!wasKeyDown) {
            this.read();
            this.setState({wasKeyDown: true});
        }
        if (e.key === 'Enter') {
            await this.send();
            this.setState({wasKeyDown: false});
        }
    }

    read = () => {
        const {data, read} = this.props;
        read(data.id);
    }

    scrollToBottom = () => {
      try{
           this.messagesEnd.scrollIntoView({ behavior: "auto" });
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
      setTimeout(this.scrollToBottom, 500);
    }

    componentDidUpdate(prevProps, prevState) {
        this.scrollToBottom();
    }

    send = async () => {
        const {data} = this.props;
        const {input, fileUrl, fileName, fileLoading} = this.state;
        if (input || fileUrl) {
            if (fileUrl && fileLoading) {
                message.error('File did not completely upload yet');
                return null;
            }
            let message = input;
            if (!message && fileName) message = fileName;
            this.setState({loading: true});
            await requests.post('tm/send_message', {
                task: data.id,
                message: message,
                file: fileUrl
            });
            this.setState({loading: false, input: null, fileName: null, fileUrl: null});
            this.inputRef.clear();
            new Audio(message_sent).play()
        }
    }

    onMessageClick = e => {
        if (e.type === 'photo') {
            const {images} = this.props;
            const index = images.indexOf(e.data.uri);
            this.setState({
                viewerIsOpen: true,
                imageIndex: index
            })
        } else if (e.type === 'file') {
            window.open(e.data.uri, '_blank');
        }
    }

    bu = async (file) => {
        if(beforeUpload(file)){
            let base64 = await getBase64(file);
            this.setState({
                fileUrl: base64,
                fileName: file.name
            })
        }
    }

    removeFile = () => {
        this.setState({
            fileName: null,
            fileUrl: null
        })
    }

    render() {
        const {loading, imageIndex, viewerIsOpen, fileUrl, fileName, fileLoading} = this.state;
        const {data, images} = this.props;
        const {messages} = data;
        messages.map((v) => {
            v.date = new Date(v.date);
        });
        const uploadProps = {
            showUploadList: false,
            beforeUpload: this.bu,
            multiple: false,
            customRequest: async () => {
                this.setState({fileLoading: true})
                const r = await requests.post('utils/upload', {file: fileUrl})
                if (r.status === 200) {
                    this.setState({
                        fileUrl: r.data.url,
                    });
                    message.success('File was uploaded successfully!')
                }
                this.setState({fileLoading: false});
            },

        };
        return <Wrapper>
            {/*<SystemMessage text={`${data.name.split('#', 1)[0]} started new chat`}/>*/}
            <MessageList
                dataSource={messages}
                onClick={this.onMessageClick}
            />
            <InputWrapper>
                <div>
                    <Input
                    placeholder="Enter message"
                    // multiline={true}
                    inputStyle={{
                        overflow: "hidden",
                        border: '1px solid #a3a3a3',
                        height: 35,
                        padding: 5,
                        width: '100%'
                    }}
                    minHeight={35}
                    ref={el => (this.inputRef = el)}
                    onChange={e => this.setState({input: e.target.value})}
                    onKeyDown={this.onKeyDown}
                    onFocus={this.read}
                />
                </div>
                <div>
                    <Upload {...uploadProps}>
                        <Button type={'link'}><PaperClipOutlined /></Button>
                    </Upload>
                </div>
                <div>
                    <Button
                    type={'primary'}
                    shape={'round'}
                    onClick={this.send}
                    loading={loading}
                    block
                >Send</Button>
                </div>
            </InputWrapper>
            {fileName && (
                <Attachment>
                    <Paragraph type={fileLoading ? 'secondary' : 'success'}>
                        {fileLoading ? <LoadingOutlined /> : <PaperClipOutlined />}{fileName} {!fileLoading && (
                            <Button onClick={this.removeFile} icon={<DeleteOutlined />} size={'xs'} type={'link'} danger/>
                        )}
                    </Paragraph>
                </Attachment>
            )}
            <div ref={(el) => { this.messagesEnd = el; }}/>
            {viewerIsOpen && (
                <ImageViewer
                  src={ images }
                  currentIndex={ imageIndex }
                  disableScroll={ false }
                  closeOnClickOutside={ true }
                  onClose={ () => this.setState({viewerIsOpen: false}) }
                />
              )}
        </Wrapper>
    }

}