import React, {Component} from 'react';
import {Typography, Empty} from "antd";
import styled from "styled-components";
import { ChatList } from "react-chat-elements";
import renderHTML from 'react-render-html';
import new_task from '../assets/sounds/new_task.mp3';


const {Title} = Typography;
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    border-right: 1px solid #a9a9a9;
    overflow-y: scroll;
    
    @media screen and (max-width: 767px) {
        border-right: none;
    }
`;

export default class Tasks extends Component {

    tasksToList = data => {
        if (data) {
            return Object.keys(data).map((v) => {
                data[v].firebaseId = v;
                return data[v];
            });
        } else {
            return [];
        }
    }

    renderItems = () => {
        const {data, onClick} = this.props;
        const list = this.tasksToList(data);
        list.map((v, i) => {
            v.date = new Date(v.date);
            v.avatar = v.photo;
            v.title = v.name;
            v.alt = v.name;
            // v.subtitle = renderHTML(v.last_message);
            v.subtitle = renderHTML(v.subject)
        })
        return <ChatList onClick={onClick} dataSource={list}/>
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            if (!prevProps.data && this.props.data) new Audio(new_task).play();
            if (prevProps.data) {
                const prevKeys = Object.keys(prevProps.data);
                if (this.props.data) {
                    const currKeys = Object.keys(this.props.data);
                    if (currKeys.length > prevKeys.length) new Audio(new_task).play();
                }
            }
        }
    }

    render() {
        const {data} = this.props;
        return <Wrapper>
            <Title level={4} className={'hide-mob'}>My tickets:</Title>
            {data ? this.renderItems() : <Empty description={'No opened tickets'}/>}
        </Wrapper>
    }

}