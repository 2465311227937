import React, {Component} from "react";
import styled from "styled-components";
import {Button, Modal, Typography, message, Badge, Drawer, Space} from "antd";
import firebase from "firebase/compat";
import Tasks from "./Tasks";
import Chat from "./Chat";
import requests from "../utils/requests";
import new_message from "../assets/sounds/new_message.mp3";
import {Navbar, Avatar} from "react-chat-elements";
import {MenuUnfoldOutlined} from '@ant-design/icons';


const {Paragraph, Text} = Typography;
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
const Body = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    color: #a3a3a3;
    div.column {
        position:  relative;
        height: calc(100vh - 60px);
    }
    
    div.column:last-child {
        padding: 10px;
    }
    
    @media screen and (max-width: 767px) {
        display: block;
    }
`;
const Hint = styled.div`
    position: absolute;
    width: 300px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
`;

export default class MainMobile extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            name: null,
            token: localStorage.getItem('token'),
            tasks: null,
            selectedChat: null,
            images: [],
            status: null,
            drawerOpen: false
        }
    }

    async componentDidMount() {
        const {token} = this.state;
        const r = firebase.database().ref('bo_tasks');
        await r.on("value", (snapshot) => {
            const data = snapshot.val();
            this.setState({
                name: data[token].name,
                tasks: data[token].tasks ? data[token].tasks : null
            });
        });
        const res = await requests.get('tm/status');
        if (res.status === 200) {
            this.setState({status: res.data.status})
        }
    }

    onChatItemSelect = ({id, firebaseId}) => {
        this.selectChat(id);
        this.setState({
            drawerOpen: false,
            selectedChat: firebaseId,
        });
    }

    selectChat = id => {
        requests.post('tm/select_chat', {task: id});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tasks !== this.state.tasks) {
            let currKeys = [];
            if (this.state.tasks) {
                currKeys = Object.keys(this.state.tasks);
            }
            if (prevState.tasks) {
                const prevKeys = Object.keys(prevState.tasks);
                let prevLen = 0;
                let currLen = 0;
                let playSound = false;
                prevKeys.map((v) => {
                    prevLen = prevState.tasks[v].messages.length;
                })
                currKeys.map((v) => {
                    currLen = this.state.tasks[v].messages.length;
                    const last = this.state.tasks[v].messages[this.state.tasks[v].messages.length - 1];
                    if (last.position === 'left') playSound = true;
                });
                if (currLen > prevLen && playSound) new Audio(new_message).play();
            }
            const images = [];
            currKeys.map((v) => {
                this.state.tasks[v].messages.map((vv) => {
                    if (vv.type === 'photo') images.push(vv.data.uri);
                    return null;
                });
                return null;
            })
            this.setState({images: images});
        }
    }

    resolve = () => {
        const {tasks, selectedChat} = this.state;
        this.setState({
            selectedChat: null
        });
        requests.post('tm/resolve', {
            task: tasks[selectedChat].id
        });
    }

    changeStatus = async () => {
        const {status} = this.state;
        const r = await requests.post('tm/set_status', {
            active: !status
        });
        if (r.status === 200) {
            this.setState({status: r.data.status})
        }
    }

    openDrawer = () => {
        this.setState({drawerOpen: true});
    }

    render() {
        const {name, tasks, selectedChat, images, status, drawerOpen} = this.state;
        return (
            <Wrapper>
                <Body>
                    <Drawer
                        title="My tickets:"
                        placement={'left'}
                        width={'100%'}
                        onClose={() => this.setState({drawerOpen: false})}
                        visible={drawerOpen}

                      >
                        <Tasks data={tasks} onClick={this.onChatItemSelect}/>
                    </Drawer>
                    <Paragraph style={{textAlign: 'right'}}>
                        <Button onClick={this.openDrawer} type={'link'} icon={<MenuUnfoldOutlined />} style={{float: 'left'}}/>
                        Welcome, <Text strong>{name}</Text> <Badge onClick={this.changeStatus} status={status ? 'success' : 'error'}/>
                    </Paragraph>
                    {tasks && tasks[selectedChat] && <Navbar
                        left={<Avatar
                            src={tasks[selectedChat].photo}
                            alt={tasks[selectedChat].name.split('#', 1)[0]}
                            size={'large'}
                            type={'circle flexible'}
                        />}
                        center={
                            <div>{tasks[selectedChat].name.split('#', 1)[0]}</div>
                        }
                        right={
                            <div><Button onClick={() => Modal.confirm({
                                title: 'Resolve ticket',
                                content: 'Do you really think you helped?',
                                okText: 'Yes',
                                cancelText: 'No',
                                onOk: this.resolve
                            })} type={'primary'} shape={'round'} block danger>Resolve</Button></div>
                        }
                    />}
                    {tasks && tasks[selectedChat] ? (
                        <Chat
                            data={tasks[selectedChat]}
                            read={this.selectChat}
                            images={images}
                        />
                    ) : <Hint>Select ticket to start chat</Hint>}
                </Body>
            </Wrapper>
        )
    }

}