import React, {cloneElement, Component} from 'react';
import styled from "styled-components";
import {Col, DatePicker, Typography, Form, Checkbox, Radio, Row, Select} from "antd";
import Spinner from "./Spinner";
import requests from "../utils/requests";
import moment from 'moment';
import {
    BarChartOutlined,
    PieChartOutlined
} from '@ant-design/icons';
import { Bar, Pie } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)


const {RangePicker} = DatePicker;
const {Title} = Typography;
const {Item} = Form;
const {Option} = Select;


const Body = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
`;


export default class Stats extends Component {

    constructor(props, context) {
        super(props, context);
        const start = moment();
        const end = moment().add('days', 1);
        this.state = {
            agents: [],
            agentsLoading: true,
            checkedAgents: [],
            start: start,
            end: end,
            status: 0,
            query: null,
            loading: true,
            chartData: null,
            chart: 1,
            typesLoading: true,
            types: [],
            checkedTypes: []
        }
    }

    async componentDidMount() {
        let r = await requests.get('tm/agents');
        if (r.status === 200) {
            const {checkedAgents} = this.state;
            r.data.agents.map((v) => {checkedAgents.push(v.value); return null;});
            this.setState({agents: r.data.agents, checkedAgents: checkedAgents, agentsLoading: false});
            r = await requests.get('tm/ticket_types');
            if (r.status === 200) {
                const {checkedTypes} = this.state;
                r.data.types.map((v) => {checkedTypes.push(v.value); return null;});
                console.log(checkedTypes)
                this.setState({types: r.data.types, checkedTypes: checkedTypes, typesLoading: false});
                await this.executeRequest();
            }
        }
    }

    onDateChange = async val => {
        await this.setState({start: val[0], end: val[1]});
        await this.executeRequest();
    }

    onAgentCheck = async val => {
        await this.setState({checkedAgents: val});
        await this.executeRequest();
    }

    onStatusChange = async val => {
        await this.setState({status: val.target.value});
        await this.executeRequest();
    }

    onTypeCheck = async val => {
        console.log(val)
        await this.setState({checkedTypes: val});
        await this.executeRequest();
    }

    buildQuery = () => {
        const {checkedAgents, start, end, status, checkedTypes} = this.state;
        let statuses = [];
        switch (status) {
            case 0:
                statuses = [2, 3, 4];
                break;
            case 1:
                statuses = [4];
                break;
            case 2:
                statuses = [2, 3];
                break;
        }
        let typeKey = 'ticket_type__pk__in';
        let typeVal = checkedTypes;
        if (checkedTypes.length === 0) { typeKey = 'ticket_type'; typeVal = null; }
        const query = {
            assigned_to__pk__in: checkedAgents,
            [typeKey]: typeVal,
            date__gte: moment(start).format('YYYY-MM-DD'),
            date__lte: moment(end).format('YYYY-MM-DD'),
            status__in: statuses
        }
        this.setState({query: query});
    }

    executeRequest = async () => {
        this.setState({loading: true});
        this.buildQuery();
        const {query} = this.state;
        const r = await requests.post('tm/stats', query);
        if (r.status === 200) this.setState({chartData: r.data.result});
        this.setState({loading: false});
    }

    getChartData = () => {
        const {chartData} = this.state;
        if (chartData) {
            return {
                labels: chartData.map(v => v.assigned_to__name),
                datasets: [
                    {
                        label: 'Tickets total',
                        data: chartData.map(v => v.total),
                        backgroundColor: [
                          "#ffbb11",
                          "#ecf0f1",
                          "#50AF95",
                          "#888888",
                            "#f40106",
                          "#2a71d0",
                            '#feff00',
                            '#87cefb',
                            '#a021f0',
                            '#000'
                        ]
                    }
                ]
            }
        }
    }

    getTicketStatusLabel = () => {
        const {status} = this.state;
        switch (status) {
            case 1:
                return 'Resolved'
            case 2:
                return 'Not resolved'
            default:
                return 'All'
        }
    }

    onChartChange = val => {
        this.setState({chart: val})
    }

    renderChart = () => {
        const {chart} = this.state;
        if (chart === 1) { return (<Bar/>) }
        else { return (<Pie/>) }
    }

    countTickets = () => {
        const {chartData} = this.state;
        let total = 0;
        chartData.map(v => {
            total += v.total
            return null;
        });
        return total;
    }

    render() {
        const {
            agentsLoading,
            agents,
            checkedAgents,
            start,
            end,
            status,
            loading,
            chart,
            typesLoading,
            types,
            checkedTypes
        } = this.state;
        return <Body>
            <Row>
                <Col md={6}>
                    <Title level={4}>Filters:</Title>
                    <Form
                        layout={'vertical'}
                    >
                        <Item
                            label={'By date'}
                        >
                            <RangePicker
                                value={[start, end]}
                                onChange={this.onDateChange}
                            />
                        </Item>
                        <Item
                            label={'By agent'}
                        >
                            {agentsLoading ? <Spinner/> : <div>
                                <Checkbox.Group
                                    options={agents}
                                    value={checkedAgents}
                                    onChange={this.onAgentCheck}
                                />
                            </div>}
                        </Item>
                        <Item
                            label={'By status'}
                        >
                            <Radio.Group value={status} onChange={this.onStatusChange}>
                                <Radio value={0}>All</Radio>
                                <Radio value={1}>Resolved</Radio>
                                <Radio value={2}>Not resolved</Radio>
                            </Radio.Group>
                        </Item>
                        <Item
                            label={'By type'}
                        >
                            {typesLoading ? <Spinner/> : <div>
                                <Checkbox.Group
                                    options={types}
                                    value={checkedTypes}
                                    onChange={this.onTypeCheck}
                                />
                            </div>}
                        </Item>
                    </Form>
                </Col>
                <Col md={18}>
                    {loading ? <Spinner/> : <div>
                        <div style={{float: 'right'}}>
                            <Select defaultValue={chart} style={{ width: 120 }} onChange={this.onChartChange}>
                              <Option value={1}><BarChartOutlined /> Bar</Option>
                              <Option value={2}><PieChartOutlined /> Pie</Option>
                            </Select>
                        </div>
                        <div style={{maxWidth: 800}}>
                            {cloneElement(this.renderChart(), {
                                data: this.getChartData(),
                                options: {
                                    responsive: true,
                                    plugins: {
                                    title: {
                                      display: true,
                                      text: `${this.getTicketStatusLabel()} tickets (${this.countTickets()})`
                                    },
                                    legend: {
                                      display: true,
                                      position: "bottom"
                                   }
                                  }
                                }
                            })}
                        </div>
                    </div>}
                </Col>
            </Row>
        </Body>
    }

}