
const config = {
  apiKey: "AIzaSyBfSGhpLUdo6sGDJuLGLgpRRg0NiVKDcOY",
  authDomain: "crex-gcg.firebaseapp.com",
  databaseURL: "https://crex-gcg.firebaseio.com",
  projectId: "crex-gcg",
  storageBucket: "crex-gcg.appspot.com",
  messagingSenderId: "212850801024",
  appId: "1:212850801024:web:cf782f57a2329ad299a965"
}

export default config;
