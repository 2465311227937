import React, {Component} from "react";
import styled from 'styled-components';
import {Card, Form, Input, Button, Typography, message} from 'antd';
import requests from "../utils/requests";


const {Title} = Typography;
const Wrapper = styled.div`
    width: 100%;
    max-width: 300px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
`;


export default class Login extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            buttonLoading: false
        }
    }

    onSubmit = async values => {
        this.setState({buttonLoading: true});
        const r = await requests.post('tm/login', values);
        if (r.status === 200) {
            const {authorize} = this.props;
            authorize(r.data.token);
        } else {
            message.error(r.error);
        }
        this.setState({buttonLoading: false});
    }

    render() {
        const {buttonLoading} = this.state;
        return (
            <Wrapper>
                <Card style={{width: '100%'}}>
                    <Title level={4}>Backoffice Support</Title>
                    <Form
                      name="basic"
                      onFinish={this.onSubmit}
                      // onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout={'vertical'}
                    >
                      <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item>
                        <Button loading={buttonLoading} block shape={'round'} type="primary" htmlType="submit">
                          Enter
                        </Button>
                      </Form.Item>
                    </Form>
                </Card>
            </Wrapper>
        )
    }
}